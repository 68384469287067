import React, { useState } from "react";
import {
  Box,
  Typography,
  Link,
  Grid,
  IconButton,
  Container,
  Collapse,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import GitHubIcon from "@mui/icons-material/GitHub";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import "./Footer.css";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const [resourcesOpen, setResourcesOpen] = useState(false);
  const [companyOpen, setCompanyOpen] = useState(false);
  const [supportOpen, setSupportOpen] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  let fontSize;
  if (isExtraSmallScreen) {
    fontSize = "0.625rem";
  } else if (isSmallScreen) {
    fontSize = "0.75rem";
  } else if (isMobile) {
    fontSize = "0.875rem";
  } else {
    fontSize = "1rem";
  }

  const handleToggle = (setter) => {
    setter((prev) => !prev);
  };

  return (
    <Box
      sx={{
        bgcolor: "var(--foterbackground)",
        p: isExtraSmallScreen ? 1 : isSmallScreen ? 2 : 6,
        borderTop: "1px solid #e0e0e0",
        width: "auto", // Ensure it covers the full width of the screen
        mx: "auto",
        px: isSmallScreen ? 2 : 0, // Add horizontal padding for small screens
      }}
      component="footer"
    >
      <Container>
        <Grid
          container
          spacing={isExtraSmallScreen ? 1 : isSmallScreen ? 2 : 4}
          justifyContent="space-between"
        >
          <Grid item xs={12} md={3}>
            <Box display="flex" alignItems="center">
              <Typography variant="h5" color="textPrimary" sx={{ fontSize }}>
                TechOptima
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              onClick={() => handleToggle(setResourcesOpen)}
              sx={{ cursor: "pointer" }}
            >
              <Typography
                variant="h6"
                color="var(--fontcolor)"
                sx={{ fontSize }}
              >
                Resources
              </Typography>
              {isMobile && (
                <IconButton>
                  {resourcesOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              )}
            </Box>
            <Collapse in={resourcesOpen || !isMobile}>
              <Link
                href="/benchmarks"
                color="inherit"
                className="footerLink"
                sx={{ fontSize }}
              >
                Benchmarks
              </Link>
              <br />
              <Link
                href="/blogs"
                color="inherit"
                className="footerLink"
                sx={{ fontSize }}
              >
                Blogs
              </Link>
              <br />
              <Link
                href="/documentation"
                color="inherit"
                className="footerLink"
                sx={{ fontSize }}
              >
                Documentation
              </Link>
              <br />
              <Link
                href="/forum"
                color="inherit"
                className="footerLink"
                sx={{ fontSize }}
              >
                Forum
              </Link>
              <br />
              <Link
                href="/research"
                color="inherit"
                className="footerLink"
                sx={{ fontSize }}
              >
                Research
              </Link>
            </Collapse>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              onClick={() => handleToggle(setCompanyOpen)}
              sx={{ cursor: "pointer" }}
            >
              <Typography
                variant="h6"
                color="var(--fontcolor)"
                sx={{ fontSize }}
              >
                Company
              </Typography>
              {isMobile && (
                <IconButton>
                  {companyOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              )}
            </Box>
            <Collapse in={companyOpen || !isMobile}>
              <Link
                href="/about#company"
                color="inherit"
                className="footerLink"
                sx={{ fontSize }}
              >
                About
              </Link>
              <br />
              <Link
                href="/careers"
                color="inherit"
                className="footerLink"
                sx={{ fontSize }}
              >
                Careers
              </Link>
              <br />
              <Link
                href="/expertguidance"
                color="inherit"
                className="footerLink"
                sx={{ fontSize }}
              >
                Expert Guidance
              </Link>
              <br />
              <Link
                href="/partners"
                color="inherit"
                className="footerLink"
                sx={{ fontSize }}
              >
                Partners
              </Link>
            </Collapse>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              onClick={() => handleToggle(setSupportOpen)}
              sx={{ cursor: "pointer" }}
            >
              <Typography
                variant="h6"
                color="var(--fontcolor)"
                sx={{ fontSize }}
              >
                Support
              </Typography>
              {isMobile && (
                <IconButton>
                  {supportOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              )}
            </Box>
            <Collapse in={supportOpen || !isMobile}>
              <Link
                href="/technicalsupport"
                color="inherit"
                className="footerLink"
                sx={{ fontSize }}
              >
                Technical Support
              </Link>
              <br />
              <Link
                href="/partnerportal"
                color="inherit"
                className="footerLink"
                sx={{ fontSize }}
              >
                Partner Portal
              </Link>
            </Collapse>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              onClick={() => handleToggle(setContactOpen)}
              sx={{ cursor: "pointer" }}
            >
              <Typography
                variant="h6"
                color="var(--fontcolor)"
                sx={{ fontSize }}
              >
                Contact
              </Typography>
              {isMobile && (
                <IconButton>
                  {contactOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              )}
            </Box>
            <Collapse in={contactOpen || !isMobile}>
              <Link
                // href="/Contact"
                onClick={() =>
                  navigate("/Contact", { state: { from: "home" } })
                }
                color="inherit"
                className="footerLink"
                sx={{ cursor: "pointer" }}
              >
                Contact Us
              </Link>
              <br />
              {/* <Typography
                  color="inherit"
                  className="footerLink"
                  sx={{ fontSize }}
                >
                  info@techoptima.in
                </Typography>
                <Typography
                  color="inherit"
                  className="footerLink"
                  sx={{ fontSize }}
                >
                  sales@techoptima.in
                </Typography> */}

              <Typography
                color="inherit"
                className="footerLink"
                sx={{ fontSize }}
              >
                <a
                  href="mailto:info@techoptima.in"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  info@techoptima.in
                </a>
              </Typography>
              <Typography
                color="inherit"
                className="footerLink"
                sx={{ fontSize }}
              >
                <a
                  href="mailto:sales@techoptima.in"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  sales@techoptima.in
                </a>
              </Typography>
              <Typography
                color="inherit"
                className="footerLink"
                sx={{ fontSize }}
              >
                <a
                  href="mailto:careers@techoptima.in"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  careers@techoptima.in
                </a>
              </Typography>
            </Collapse>
          </Grid>
        </Grid>

        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="space-between"
          alignItems="center"
          mt={4}
          sx={{ fontSize }}
        >
          <Typography color="textSecondary" sx={{ fontSize }}>
            &copy; 2024 All rights reserved.{" "}
            <Link
              // href="https://www.techoptima.in/"
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ textDecoration: "none" }}
            >
              TechOptima
            </Link>{" "}
            Copy Rights.
          </Typography>
          <Box display={isMobile ? "block" : "flex"} mt={isMobile ? 2 : 0}>
            <Link
              href="/termsofservices"
              color="inherit"
              sx={{
                marginRight: isMobile ? 0 : "20px",
                fontSize,
                display: isMobile ? "block" : "inline",
              }}
              className="footerLink"
            >
              Terms of Service
            </Link>
            <Link
              href="/privacypolicy"
              color="inherit"
              className="footerLink"
              sx={{ fontSize, display: isMobile ? "block" : "inline" }}
            >
              Privacy Policy
            </Link>
          </Box>
          <Box display="flex" justifyContent="center" mt={2}>
            <IconButton
              href="https://x.com/TechOptima0127"
              target="_blank"
              color="inherit"
            >
              <XIcon />
            </IconButton>
            <IconButton
              href="https://www.linkedin.com/company/techoptima/"
              target="_blank"
              color="inherit"
            >
              <LinkedInIcon />
            </IconButton>
            <IconButton
              href="https://www.youtube.com/channel/UCsYWn0H6k5BGheE-3h7zsZw"
              target="_blank"
              color="inherit"
            >
              <YouTubeIcon />
            </IconButton>
            <IconButton
              href="https://github.com/"
              target="_blank"
              color="inherit"
            >
              <GitHubIcon />
            </IconButton>
            <IconButton
              href="https://www.instagram.com/techoptima.ai/?igsh=MWkzMXIxZWp5c20wbw%3D%3D"
              target="_blank"
              color="inherit"
            >
              <InstagramIcon />
            </IconButton>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
